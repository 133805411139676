@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.smartContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  .topSmart {
    color: #fff;
    font-style: bold;
    font-size: 60px;
    line-height: 100%;
    padding-top: 100px;
    display: inline-block;
    margin-bottom: 40px;
  }
}
.contactCardContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  .contactCard {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1px 2px 2px 2px;
    background: linear-gradient(135deg, #fff 0%, #000 100%);
    border-radius: 20px;
    max-width: 300px;
    width: 100%;

    &:hover {
      background: linear-gradient(315deg, #fff 0%, #000 100%);
    }
    .card {
      background: #120d18;
      width: 100%;
      box-sizing: border-box;
      padding: 50px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      
      border-radius: 20px;
      .logo {
        width: 60px;
        height: 60px;
        padding-bottom: 20px;
      }
      .name {
        width: 206px;
        padding-bottom: 40px;
        text-align: center;
        font-size: 32px;
        font-weight: 800;
        line-height: 40px;
        white-space: nowrap;
        
      }
      .button {
        padding: 16px 48px;
        font-weight: 900;
        font-size: 18px;
        line-height: 24px;
        border-radius: 50px;
        background: $gradient90deg;
        color: #fff;
        z-index: 1;
        position: relative;
        border: none;
        cursor: pointer;

        &::after {
          content: "";
          position: absolute;
          left: 0%;
          right: 0%;
          top: 72.38%;
          bottom: 0%;
          background: linear-gradient(98.01deg, #ff00e5 -1.14%, #7000ff 90.23%);
          filter: blur(19px);
          z-index: -1;
        }

        &:hover {
          background: $gradientPink;
        }
        &:active {
          &::after {
            content: none;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1300px){
  .contactCardContainer{
    display: flex;
    flex-direction: column;
    align-items: center !important;
    .contactCard{
      margin-bottom: 40px;
    }
  }
  .smartContainer{
    text-align: center;
    .topSmart{
      font-size: 32px !important;
    }
  }
}