@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.dropdown {
  position: relative;
  border: 3px solid #000;
  border-bottom: 10px solid $gradient90deg ;
}

.header {
  color: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  // top: 27px;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 10;
  background: #000000;
  border-radius: 10px;
  padding-top: 27px;
  padding-bottom: 20px;
  .wrapper {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1626px;
    width: 100%;
    padding: 0 15px ;
  }
  .link {
    font-size: 20px;
    line-height: 25px;
    color: #fff;
    white-space: nowrap;
    text-decoration: none;
    cursor: pointer;
    z-index: 1;
    position: relative;
    &:hover {
      &::after {
        content: " ";
        height: 2px;
        width: 100%;
        position: absolute;
        bottom: -10px;
        left: 0px;
        right: 0px;
        background: $gradient90deg;
      }
    }
    &:active {
      @include gradientText90deg;
    }
  }
  .button {
    padding: 16px 16px;
    font-weight: 900;
    width: 231px;
    font-size: 16px;
    line-height: 24px;
    border-radius: 50px;
    text-transform: uppercase;
    background: $gradient90deg;
    color: #fff;
    z-index: 1;
    position: relative;
    border: none;
    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      left: 0%;
      right: 0%;
      top: 72.38%;
      bottom: 0%;
      background: linear-gradient(98.01deg, #ff00e5 -1.14%, #7000ff 90.23%);
      filter: blur(19px);
      z-index: -1;
    }

    &:hover {
      background: $gradientPink;
    }
    &:active {
      &::after {
        content: none;
      }
    }
  }

  .burger {
    display: none;
  }

  .cross {
    display: none;
  }
  

  
}
@media screen and (max-width: 1350px) {
  .popover{
    display: none;
  }
  .link {
    display: none;
  }
  .button {
    display: none;
  }
  .burger {
    cursor: pointer;
    margin-left: auto; 
    display: inline !important;
  }

  .logo {
    display: flex;
  }

  .responsive {
    width: 100% !important;
    height: 100vh !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
    align-items: center !important;
    // position: relative !important;
    padding: 90px 15px 50px 15px;
    box-sizing: border-box;
    top: 0;
    background: #000;
    // position: fixed;
    .wrapper {
      width: 100% !important;
    height: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
    align-items: center !important;
    }
    .logo{
      position: absolute;
      left: 16px;
      top: 33px;
    }
    .cross {
      cursor: pointer;
      display: inline;
      position: absolute;
      right: 5px;
      top: 15px;
      transform: scale(0.7);
    }

    .burger {
      display: none !important;
    }

    .link,
    .popover {
      display: initial;
    }

    .button {
      display: block;

      .arrow {
        display: none;
      }
    }

  }
}