@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.container {
  margin-bottom: 80px;

  .heading {
    @include gradientText90deg;
    font-weight: 800;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 20px;
  }

  .content {
    font-size: 24px;
    line-height: 30px;
  }
}

@media screen and (max-width: 1200px){
  .container{
    display: flex;
    flex-direction: column;
    align-items: center !important;
    .heading{
      text-align: center;
    }
    .content{
      text-align: center;
    }
  }
}