@import "../../styles/variables.scss";

.container {
  max-width: 938px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .bg {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 100%;
  }
  .heading {
    font-weight: 800;
    font-size: 60px;
    line-height: 76px;
    margin-bottom: 40px;
  }

  .textFields {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 40px;
  }
  .textFieldContainer {
    width: 30%;
  }

  .textFieldError {
    .fieldLabel {
      color: #ff0000 !important;
    }
    .textField {
      background-image: linear-gradient(#2b1f22, #2b1f22),
        linear-gradient(170deg, #ff0000 0%, #2b1f22 70%);
      color: #ff0000;

      &::placeholder {
        color: #ff000088;
      }

      &:focus {
        background-image: linear-gradient(#2b1f22, #2b1f22),
          linear-gradient(170deg, #ff0000 0%, #2b1f22 70%);
      }
    }
  }

  .fieldLabel {
    margin-bottom: 20px;
  }

  .textArea {
    width: 100%;
    margin-bottom: 40px;
    background-color: #c6c6c656;
  }

  .containerButton {
    background: $gradient90deg;
    padding: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    margin: 0 auto 20px auto;
    width: fit-content;

    .button {
      padding: 16px 48px;
      font-weight: 900;
      font-size: 18px;
      line-height: 24px;
      border-radius: 50px;
      background: black;
      color: #fff;
      z-index: 1;
      position: relative;
      border: none;
      cursor: pointer;

      &:hover {
        background: $gradientPink;
      }

      &::after {
        content: "";
        position: absolute;
        left: 0%;
        right: 0%;
        top: 72.38%;
        bottom: 0%;
        background: linear-gradient(98.01deg, #ff00e5 -1.14%, #7000ff 90.23%);
        filter: blur(19px);
        z-index: -1;
      }

      &:hover {
        background: transparent;
      }
      &:active {
        &::after {
          content: none;
        }
      }
    }
  }

  .loader {
    display: flex;
    margin: 20px 0;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 600px) {
    align-items: center;
    .textFields {
      flex-direction: column;
    }
    .fieldLabel {
      margin-top: 20px;
    }
    .textFieldContainer {
      display: flex;
      flex-direction: column;
      width: 100% !important;
      align-items: center;
      margin-bottom: 40px;
      .textField {
        // background-color: #c6c6c656;
        width: 100%;
      }
    }
    .containerButton {
      width: 100%;
      .button {
        width: 100%;
      }
    }
  }
}
