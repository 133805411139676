.container {
  max-width: 1626px;
  position: relative;
  .girlContainer{
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    border-radius: 70px;
  .girlImg {
    height: 100%;

  }
  .girlImgM{
    height: 100%;
    display: none;
  }
  }
  .rocket {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    .rocketContainer {
        position: relative;
        z-index: 1;
        &:after {
          content: "";
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          background: linear-gradient(
            165.28deg,
            #ff00e5 -17.39%,
            #5200ff 128.99%
          );
          opacity: 0.3;
          filter: blur(20px);
          z-index: -1;
          position: absolute;
        }
      .rocketImg {

      }
    }
  }
}

@media screen and (max-width: 700px) {
    .girlContainer{
      .girlImg{
        display: none;
      }
      .girlImgM{
        display: initial !important;
      }
    }
}
