.layout {
  background-color: #000;
  background: url('../../../public/images/bogdan.png') repeat-y;
  background-size: cover;
  .content {
    padding: 120px 0;
    padding-bottom: 0;
    width: 100%;
    overflow: hidden;
    position: relative;
  }
  .footer{
    position: relative;
    z-index: 1;
    &::after {
      content: '';
      position: absolute;
      left: 0%;
      right: 0%;
      top: 0%;
      bottom: 0%;
      background: #000;
      opacity: 0.55;
      z-index: -1;
    }
  }
}