@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.page {

  .bg{
    position: absolute;
    left: 0;
    z-index: -1;
    width: 100%;
  }
  .heading {
    font-weight: 800;
    font-size: 60px;
    line-height: 76px;
    margin-bottom: 40px;
  }

  .questionContainer {
    position: relative;

    .questions {
      max-width: 776px;
      width: 100%;
    }
  }

  .loader {
    display: flex;
    margin: 20px 0;
    justify-content: center;
    align-items: center
  }

  .nothing {
    text-align: center;
    padding: 30px 0;
  }
}

@media screen and (max-width: 1200px){
  .page{
    
    .heading{
      width: fit-content;
      margin: 0 auto !important;
      padding-bottom: 40px !important;
    }
    .question{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
