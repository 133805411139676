@import "../../styles/mixins.scss";

.footer {
  padding-top: 140px;
  padding-bottom: 80px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .footerBlock {
    width: 40%;
    .heading {
      margin-bottom: 54px;
    }
    .logo {
      width: 284px;
    }

    .containerLinks {
      margin-bottom: 80px;
      display: flex;
    }

    .wishes {
      margin-bottom: 20px;
    }

    .rights {
      opacity: 0.25;
    }

    .contact {
      @include gradientText90deg;
      display: inline-block;
      font-weight: 800;
      font-size: 32px;
    }

    .icons {
      display: flex;
      width: 50%;
      justify-content: space-between;
      .iconContainer{
        width: 50%;
        box-sizing: border-box;
        margin: 0;
        display: flex;
        justify-content: center;
        margin-bottom: 40px;
        height: 48px;
        position: relative;
      &:hover{
        .icon{
          visibility: hidden !important;
        }
        .iconHover{
          visibility: visible !important;
        }
      }
      &:active{
        .iconPress{
          visibility: visible !important;
        }
        .iconHover{
          visibility: hidden !important;
        }
      }
      .icon {
        height: 16px;
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
        visibility: visible;
      }
      .iconHover{
        height: 16px;
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
        visibility: hidden;
      }
      .iconPress{
        height: 16px;
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
        visibility: hidden;
      }
    }
    }
  }
  .linkBlock {
    padding-right: 40px;
    font-size: 20px;
    font-weight: 600;
    display: flex;
    flex-direction: column;

    &:nth-last-child(1) {
      padding-right: 0;
    }
  }
}


@media screen and (max-width: 1000px){
  
  .footer{
    display: flex;
    flex-direction: column;
    align-items: center !important;

    .footerBlock{
      display: flex;
      flex-direction: column;
      align-items: center !important;
      width: 100% !important;
      text-align: center !important;
      .logo{
        
      }
      .containerLinks{
        flex-direction: column;
        
      }
      .linkBlock{
        align-items: center;
        padding-right: 0 !important;
        width: 100% !important;
      }
      .iconContainer{
        margin-bottom: 80px !important;
        .icon, .iconHover, .iconPress{
          margin: 0 auto !important;
          left: 0 !important;
          right: 0 !important;
          padding-top: 40px !important;
        }
      }
    }
  }
}
.link {
  text-decoration: none;
  padding-bottom: 20px;

  &:hover {
    @include gradientText90deg;
  }
  &:active {
    color: $gradientPurple;
  }
}