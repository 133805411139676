.textFieldContainer {
  width: 100%;
}

.textFieldError {
  .fieldLabel {
    color: #ff0000 !important;
  }
  .textField, textArea {
    background-image: linear-gradient(#2b1f22, #2b1f22),
      linear-gradient(170deg, #ff0000 0%, #2b1f22 70%);
    color: #ff0000;

    &::placeholder {
      color: #ff000088;
    }

    &:focus {
      background-image: linear-gradient(#2b1f22, #2b1f22),
        linear-gradient(170deg, #ff0000 0%, #2b1f22 70%);
    }
  }
}

.fieldLabel {
  margin-bottom: 20px;
}

.textArea {
  width: 100%;
  margin-bottom: 40px;
  background-color: #c6c6c656;
  min-height: 400px;
  resize: none;
  margin-bottom: 40px;
}

@media screen and (max-width: 600px){
  .fieldLabel{
    text-align: center;
  }
}