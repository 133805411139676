@import '../../styles/variables.scss';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 45px;
  h2 {
    text-align: center;
    width: 100%;
    color: #ccc;
    opacity: 0.8;
  }
  .bg{
    position: absolute;
    left: 0;
    z-index: -1;
    width: 100%;
  }
  .heading {
    font-weight: 800;
    font-size: 60px;
    line-height: 76px;
    margin-bottom: 110px;
  }
  .lots {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .containerButton {
    background: $gradient90deg;
    padding: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    margin-top: 80px;

    .button {
      padding: 16px 48px;
      font-weight: 900;
      font-size: 18px;
      line-height: 24px;
      border-radius: 50px;
      background: black;
      color: #fff;
      z-index: 1;
      position: relative;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;

      &:hover {
        background: $gradientPink;
      }

      &::after {
        content: "";
        position: absolute;
        left: 0%;
        right: 0%;
        top: 72.38%;
        bottom: 0%;
        background: linear-gradient(98.01deg, #ff00e5 -1.14%, #7000ff 90.23%);
        filter: blur(19px);
        z-index: -1;
      }

      &:hover {
        background: transparent;
      }
      &:active {
        &::after {
          content: none;
        }
      }
    }
  }

  @media screen and (max-width: 1150px) {
    .lots {
      flex-direction: column;
      align-items: center
    }
  }
  @media screen and (max-width: 600px) {
    .containerButton {
      width: 100%;
      .button {
        width: 100%;
        justify-content: center;
      }
    }
  }
}