html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: Outfit, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background: black;
}

* {
  margin: 0;
  padding: 0;
  color: white;
  outline: none;
}

.web3modal div {
  z-index: 10000;
}