@import "../../styles/mixins.scss";

.benefits {
  // background: url(../../../public/images/smoke2.png);
  // background-position: 50% 50%;
  // background-repeat: no-repeat;
  // background-size: contain;
  .bg{
    position: absolute;
    left: 0;
    z-index: -1;
    width: 100%;
  }
  .benefitsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    .topBenefits {
      color:#fff;
      font-style: bold;
      font-size: 60px;
      line-height: 100%;
      padding-top: 169px;
      display: inline-block;
      margin-bottom: 43px;
    }
  }
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;

  .card {
    position: relative;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 81px;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: -28px;
      height: 1px;
      opacity: 0.3;
      background: linear-gradient(
        92.19deg,
        rgba(255, 0, 229, 0) 2.62%,
        #ff00e5 38.55%,
        #7000ff 59.71%,
        rgba(112, 0, 255, 0) 97.11%
      );
    }
    .textBenefits {
      color: #fff;
      font-size: 24px;
      text-align: left;
      font-weight: bold;
      padding-right: 40px;
    }
    .imgBenefits {
      width: 60px;
      height: 60px;
      padding-bottom: 40px;
    }
  }
}

@media screen and (max-width: 1000px) {
  .cardContainer{
    display: flex;
    flex-direction: column;
    align-items: center !important;
    .card{
      display: flex;
      flex-direction: column;
      align-items: center !important;
      width: initial !important;
      .textBenefits{
        text-align: center !important;
        padding-right: 0px !important;
      }
    }
  }
  .benefits{
    .benefitsContainer{
      .topBenefits{
        font-size: 32px !important;
      }
    }
  }
}