@import "../../styles/mixins.scss";

.container {
  background-image: url("../../../public/images/stars.svg");
  background-size: cover;

  .card {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    .textContainer {
      max-width: 450px;
      padding-right: 40px;
      .topText {
        font-size: 32px;
        text-align: left;
        @include gradientText90deg;
        padding-bottom: 20px;
      }

      .textEco {
        color: #fff;
        text-align: left;
        font-size: 24px;
        line-height: 30px;
      }
    }

    .containerImg {
      flex-grow: 1;
      overflow: hidden;
      border-radius: 52px;
      height: fit-content;
      .ecoImg {
        width: 100%;
        transition: all 1s ease-out;

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }

  .topEcoContainer {
    display: flex;
    .topEco {
      color: #fff;
      font-style: bold;
      font-size: 60px;
      line-height: 100%;
      padding-top: 100px;
      display: inline-block;
      padding-bottom: 41px;
    }
  }
}

@media screen and (max-width: 1000px) {
    .topEcoContainer{
      display: flex;
      justify-content: center;
      .topEco{
        text-align: center;
        display: inline-block;
        font-size: 32px !important;
      }
    }
  
  .card{
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    max-width: 460px;
    margin: 0 auto;
    .textContainer{
      padding-right: 0px !important;
      .topText{
        // display: flex;
        // justify-content: center;
        text-align: center !important;
        width: 100%;
      }
      .textEco{
        text-align: center !important;
      }
    }
    .containerImg{
      margin-bottom: 40px;
    }
  }

}
