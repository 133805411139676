@import "../../styles/mixins.scss";

.container {
  background-image: url("../../../public/images/stars.svg");
  background-size: cover;
  padding-top: 100px;
  padding-bottom: 100px;
  
  max-width: 1626px;

  .story {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 3px;
      height: 1px;

      background: linear-gradient(
        92.19deg,
        rgba(255, 0, 229, 0) 2.62%,
        #ff00e5 38.55%,
        #7000ff 59.71%,
        rgba(112, 0, 255, 0) 97.11%
      );
    }
    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 1px;
      height: 1px;

      background: linear-gradient(
        92.19deg,
        rgba(255, 0, 229, 0) 2.62%,
        #ff00e5 38.55%,
        #7000ff 59.71%,
        rgba(112, 0, 255, 0) 97.11%
      );
    }
    .topStoryContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      .topStory {
        color: #fff;
        font-style: bold;
        font-size: 60px;
        line-height: 100%;
        padding-top: 40px;
        display: inline-block;
      }
    }
    .textStory {
      color: #fff;
      font-size: 24px;
      padding: 140px 40px;
      text-align: left;
      font-weight: bold;
      width: 45%;
      z-index: 1;
    }
    .ship {
      position: absolute;
      top: 80px;
      right: -95px;
      height: 510px;
      z-index: -1;
    }
  }
}

@media screen and (max-width: 1000px){
    .pena{
      display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin: 0 auto;
    }
    .textStory{
      padding: 20px 0 200px 0 !important;
      width: initial !important;
      text-align: center !important;
    }
    .ship{
      height: initial !important;
      bottom: 0 !important;
      top: initial !important;
      margin: 0 auto;
      right: initial !important;
      max-width: 100%;
      width: 100%;
    }
    .topStoryContainer{
      .topStory{
        font-size: 32px !important;
      }
    }
}