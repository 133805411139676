@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.container {
  max-width: 1500px;
  .bg{
    position: absolute;
    left: 0;
    z-index: -1;
    width: 100%;
  }
  .teamContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    .topTeam {
      color: #fff;
      font-style: bold;
      font-size: 60px;
      line-height: 100%;
      padding-top: 100px;
      display: inline-block;
      margin-bottom: 40px;
    }
  }
  .teamWrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    .teamCardContainer {
      width: 25%;
      display: flex;
      flex-direction: column;
      padding: 40px;
      align-items: center;
      box-sizing: border-box;
      .fouder {
        font-size: 26px;
        @include gradientText90deg;
        padding: 15px 0;
      }
      .avaContainer {
        position: relative;
        &:after {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          bottom: 3px;
          height: 1px;

          background: linear-gradient(
            92.19deg,
            rgba(255, 0, 229, 0) 2.62%,
            #ff00e5 38.55%,
            #7000ff 59.71%,
            rgba(112, 0, 255, 0) 97.11%
          );
        }
        .ava {
          padding-bottom: 41px;
        }
      }

      .name {
        padding-bottom: 20px;
      }
      .socialsContainar {
        display: flex;
        justify-content: center;
       .iconContainer{
         width: 32px;
         height: 32px;
         margin: 0 10px;
         position: relative;
         z-index: 1;
         display: inline-block;
         &:hover{
           .icon{
             visibility: hidden !important;
           }
           .iconHover{
             visibility: visible !important;
           }
         }
         &:active{
          .iconPress{
            visibility: visible !important;
          }
          .iconHover{
            visibility: hidden !important;
          }
        }
        .icon {
          position: absolute;
          top: 0;
          left: 0;
          cursor: pointer;
          visibility: visible;
        }
        .iconHover{
          position: absolute;
          top: 0;
          left: 0;
          cursor: pointer;
          visibility: hidden;
        }
        .iconPress{
          position: absolute;
          top: 0;
          left: 0;
          cursor: pointer;
          visibility: hidden;
        }
      }
    }
  }
}
}

@media screen and (max-width: 1000px){
  .teamWrapper{
    display: flex;
    flex-direction: column;
    align-items: center !important;
    .iconContainer{
      padding-bottom: 50px;
    }
  .teamCardContainer{
    display: flex;
    flex-direction: column;
    align-items: center !important;
    width: 100% !important;
    padding: 0 !important;
    .name{
      text-align: center;
      
    }
  }
}
  .container{
    .teamContainer{
      .topTeam{
        font-size: 32px !important;
      }
    }
  }
}