@import "../../styles/mixins.scss";
.container {
  display: flex;
  padding: 0 10px 40px 10px;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  width: 50%;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;

    background: linear-gradient(
      92.19deg,
      rgba(255, 0, 229, 0) 2.62%,
      #ff00e5 38.55%,
      #7000ff 59.71%,
      rgba(112, 0, 255, 0) 97.11%
    );
  }
  // &:before {
  //   content: "";
  //   position: absolute;
  //   left: 0;
  //   right: 0;
  //   top: 1px;
  //   height: 1px;

  //   background: linear-gradient(
  //     92.19deg,
  //     rgba(255, 0, 229, 0) 2.62%,
  //     #ff00e5 38.55%,
  //     #7000ff 59.71%,
  //     rgba(112, 0, 255, 0) 97.11%
  //   );
  // }

  .lotImage {
    margin-right: 40px;
    width: 286px !important;
  }

  .lotInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .lotName {
      @include gradientText180deg;
      font-weight: 800;
      font-size: 32px;
      line-height: 40px;
      padding-bottom: 20px;
    }

    .lotFloorPrice {
      font-size: 18px;
      line-height: 23px;
      padding-bottom: 20px;
    }

    .lotPrice {
      @include gradientText90deg;
      font-weight: 600;
      font-size: 20px;
      line-height: 25px;
      padding-bottom: 20px;
    }

    .button {
      padding: 16px 64px;
      font-weight: 900;
      font-size: 18px;
      line-height: 24px;
      border-radius: 50px;
      background: $gradient90deg;
      color: #fff;
      z-index: 1;
      position: relative;
      border: none;
      cursor: pointer;

      &::after {
        content: "";
        position: absolute;
        left: 0%;
        right: 0%;
        top: 72.38%;
        bottom: 0%;
        background: linear-gradient(98.01deg, #ff00e5 -1.14%, #7000ff 90.23%);
        filter: blur(19px);
        z-index: -1;
      }

      &:hover {
        background: $gradientPink;
      }
      &:active {
        &::after {
          content: none;
        }
      }
    }
  }
  @media screen and (max-width: 700px) {
    flex-direction: column;
    align-items: center;
    .lotImage {
      margin-bottom: 40px;
      margin-right: 0;
    }
    .lotInfo {
      align-items: center;

      .lotName {
        text-align: center;
      }
    }
    width: auto;

  }
  // margin-right: ;
}
