.slick-slide {
  transition: all 0.5s ease-out;
  padding: 25px 0;
  transform: scale(0.7);
}
.slick-center {
  transform: scale(1.05);
  // padding: 0 40px;
}

@media  screen and (max-width: 450px) {
  .slick-slide {
    transform: scale(0.5);
    opacity: 0;
  }
  .slick-center {
    transform: scale(0.9);
    opacity: 1;
  }
}