@import "../../styles/mixins.scss";

.modal {
  position: relative;
  margin: 0 auto;
  padding: 28px 16px;
  max-width: 900px;
  width: 100%;
  box-sizing: border-box;
  background: white;
  border-radius: 60px;

  .cross {
    position: absolute;
    top: 23px;
    right: 16px;
    cursor: pointer;
  }

  .container {
    max-width: 600px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
  }

  .heading {
    @include gradientText90deg;
    font-weight: 800;
    font-size: 60px;
    line-height: 76px;
    text-align: center;
    margin-bottom: 20px;
  }

  .subtitle {
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: black;
    margin-bottom: 40px;
  }

  .actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .buttonContainer {
      background: $gradient90deg;
      padding: 2px 2px 2px 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 139px;
      
      margin: 0 auto 30px auto;

      .buttonContainer2 {
        border-radius: 50px;
        border: none;
        display: flex;
        align-items: center;
        background: #fff;
        justify-content: center;
        cursor: pointer;
        padding: 28px 46px;
        .buttonText {
          @include gradientText90deg;
          font-weight: 900;
          font-size: 18px;
          line-height: 24px;
        }
      }
    }
  }
  .buttonConnect {
    padding: 16px 48px;
    font-weight: 900;
    font-size: 18px;
    line-height: 24px;
    border-radius: 50px;
    background: $gradient90deg;
    color: #fff;
    z-index: 1;
    position: relative;
    border: none;
    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      left: 0%;
      right: 0%;
      top: 72.38%;
      bottom: 0%;
      background: linear-gradient(98.01deg, #ff00e5 -1.14%, #7000ff 90.23%);
      filter: blur(19px);
      z-index: -1;
    }

    &:hover {
      background: $gradientPink;
    }
    &:active {
      &::after {
        content: none;
      }
    }
  }

  @media screen and (max-width: 650px) {
    .actions {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      .buttonContainer{
        width: 100%;
        .buttonContainer2{
          width: 100%;
        }
      }
      
    }
    
  }


  @media screen and (max-width: 400px) {
    .heading {
      font-size: 32px !important;
      line-height: 40px;
      padding-top: 48px;
    }
    .modal{
      border-radius: 0px !important;
      max-width: 100%;

    }
    .actions {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      .button {
        width: 100%;
      }
    }
  }
}
.connect{
  border-radius: 60px !important;
  // max-width: calc(100%-32px) !important;
  @media screen and (max-width: 1000px) {
    max-width: calc(100% - 32px) !important;
    width: calc(100% - 32px) !important;
    margin-left: 16px !important;
    margin-right: 16px !important;
  }
}
.paper {
  border-radius: 60px !important;
  // max-width: calc(100%-32px) !important;
  @media screen and (max-width: 1000px) {
    max-width: calc(100% - 32px) !important;
    width: calc(100% - 32px) !important;
    margin-left: 16px !important;
    margin-right: 16px !important;

  }
  @media screen and (max-width: 450px) {
    border-radius: 0 !important;
    margin: 0 !important;
    width: 100% !important;
    max-width: 100% !important;
  }
}
