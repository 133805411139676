@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.socialsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  .topSocials {
    color: #fff;
    font-style: bold;
    font-size: 60px;
    line-height: 100%;
    padding-top: 140px;
    display: inline-block;
    margin-bottom: 40px;
  }
}

.socialsWrapper {
  padding-bottom: 45px;

}

.pena {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  .textSocials {
    color: #fff;
    text-align: center;
    font-size: 24px;
    margin-bottom: 40px;
  }
  .socialsImg {
    padding-bottom: 30px;
    display: flex;
    justify-content: center;
    .iconContainer {
      z-index: 1;
      position: relative;
      display: inline-block;
      width: 50px;
      height: 48px;
      margin: 0 21.5px;
      &:hover {
        .icon {
          visibility: hidden !important;
        }
        .iconHover {
          visibility: visible !important;
        }
      }
      &:active {
        .iconPress {
          visibility: visible !important;
        }
        .iconHover {
          visibility: hidden !important;
        }
      }

      .icon {
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
        visibility: visible;
      }
      .iconHover {
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
        visibility: hidden;
      }
      .iconPress {
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
        visibility: hidden;
      }
    }
  }

  .rrocket {
    display: flex;
    align-items: center;
    cursor: pointer;

    .download {
      text-decoration: none;
      font-size: 32px;
      @include gradientText180deg;
      font-weight: 800;
      padding: 60px 0;
    }
    .rocket45 {
      padding-left: 35px;
      .rocketContainer {
        position: relative;
        top: -10px;
        z-index: 1;
        &:after {
          content: "";
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          background: linear-gradient(
            165.28deg,
            #ff00e5 -17.39%,
            #5200ff 128.99%
          );
          opacity: 0.3;
          filter: blur(20px);
          z-index: -1;
          position: absolute;
        }
        .rocketImg {
          position: absolute;
          top: 0;
          left: 0;
          transition: transform 0.5s ease-out;
          visibility: visible;
        }
        .rocketPinkImg {
          width: 46px;
          height: 46px;
          position: absolute;
          top: -6px;
          left: -6px;
          visibility: hidden;
        }
      }
    }
    &:hover {
      .download {
        @include gradientText90deg;
      }
      .rocketImg {
        transform: rotate(45deg);
      }
    }

    &:active {
      .download {
        color: $gradientPurple;
      }
      .rocketImg {
        top: 100px;
        visibility: hidden !important;
      }
      .rocketPinkImg {
        visibility: visible !important;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .socialsContainer {
    text-align: center;
    .topSocials{
      font-size: 32px !important;
      padding-top: 100px !important;
    }
  }
  .pena {
    .socialsImg {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      .iconContainer{
       width: 50%;
       box-sizing: border-box;
       margin: 0;
       display: flex;
       justify-content: center;
       margin-bottom: 40px;
       height: 48px;

       .icon{
         
       }
       img{
         margin: 0 auto;
         right: 0 !important;
       }
      }
    }
    .rrocket {
      flex-direction: column !important;
      align-items: center !important;
      .rocket45{
        height: 34px;
        width: 34px;
        padding-left: 0 !important;
      }
      .download {
        text-align: center;
        width: 100%;
      }
    }
  }
}
