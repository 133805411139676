@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.modal {
  position: relative;
  margin: 0 auto;
  padding: 28px 16px;
  max-width: 900px;
  width: 100%;
  box-sizing: border-box;
  background: white;
  border-radius: 60px;

  .cross {
    position: absolute;
    top: 23px;
    right: 16px;
    z-index: 1;
    cursor: pointer;
  }

  .container {
    max-width: 508px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;

    .cross {
      position: absolute;
      top: 23px;
      right: 16px;
      z-index: 1;
      cursor: pointer;
    }

    .heading {
      @include gradientText180deg;
      font-weight: 800;
      font-size: 60px;
      line-height: 76px;
      text-align: center;
      margin-bottom: 20px;
    }

    .timeContainer {
      display: flex;
      justify-content: space-between;
      padding: 15px 15px;
      margin-bottom: 20px;
      position: relative;
      width: 100%;
      box-sizing: border-box;

      &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 1px;
        height: 1px;

        background: linear-gradient(
          92.19deg,
          rgba(255, 0, 229, 0) 2.62%,
          #ff00e5 38.55%,
          #7000ff 59.71%,
          rgba(112, 0, 255, 0) 97.11%
        );
      }
      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 1px;
        height: 1px;

        background: linear-gradient(
          92.19deg,
          rgba(255, 0, 229, 0) 2.62%,
          #ff00e5 38.55%,
          #7000ff 59.71%,
          rgba(112, 0, 255, 0) 97.11%
        );
      }
      .timeItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        .timeValue {
          @include gradientText90deg;
          font-weight: 800;
          font-size: 60px;
          line-height: 76px;
        }
        .timeType {
          @include gradientText90deg;
          font-weight: 500;
          font-size: 18px;
          line-height: 23px;
        }
      }
    }

    .description {
      font-size: 24px;
      line-height: 30px;
      color: black;
      margin-bottom: 40px;
    }
    .floorPriceGradient {
      background: $gradient90deg;
      box-sizing: border-box;
      padding: 2px 3px 3px 3px;
      margin-bottom: 30px;
      width: 100%;
      position: relative;
      .floorPriceContainer {
        padding: 20px 0;
        margin: 0 auto;
        background: #fff;
        display: flex;
        justify-content: center;
        .floorPrice {
          font-weight: 800;
          font-size: 32px;
          line-height: 40px;
          color: black;
        }

        .floorPriceValue {
          @include gradientText180deg;
          font-weight: 800;
          font-size: 32px;
          line-height: 40px;
        }
      }
    }

    .bids {
      font-size: 24px;
      line-height: 30px;
      color: #000;
      margin-bottom: 20px;
      background: none;
      border: none;
      cursor: pointer;
    }

    .bidAmount {
      border: 1px solid black;
      font-weight: 900;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 40px;
      color: black;
      padding: 20px 40px;
      background: transparent;
    }

    .bidQuantity {
      @include gradientText180deg;
      font-weight: 800;
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 40px;
      text-align: center;
    }

    .actions {
      display: flex;
      justify-content: center;

      .containerClose {
        background: $gradient90deg;
        padding: 3px 3px 3px 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        margin-left: 20px;

        .containerClose2 {
          background: #fff;
          border-radius: 50px;
          .close {
            @include gradientText90deg;
            padding: 16px 48px;
            font-weight: 900;
            font-size: 18px;
            line-height: 24px;
            border-radius: 50px;
            z-index: 1;
            border: none;
            cursor: pointer;
          }
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    padding-top: 60px !important;
    .container {
      padding-top: 50px;
    }

    .heading {
      font-size: 32px !important;
      line-height: 40px !important;
    }
    .timeContainer {
      padding-left: 0 !important;
      padding-right: 0 !important;
      .timeItem {
        width: 25%;

        .timeValue {
          font-size: 32px !important;
          line-height: 40px !important;
        }
        .timeType {
          font-size: 18px !important;
          line-height: 23px !important;
        }
      }
    }
    .description {
      text-align: center;
    }
    .floorPriceContainer {
      flex-direction: column;
      text-align: center;
      .floorPrice {
        margin-bottom: 20px;
      }
    }
    .actions {
      flex-direction: column;
      .submit {
        margin-bottom: 20px !important;
      }
      .containerClose {
        margin-left: 0 !important;
      }
    }
  }
}

.containerBid {
  max-width: 820px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  position: relative;
  padding: 50px 16px 15px 16px;
  box-sizing: border-box;

  .nothingHere {
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    h1 {
      color: #ccc;
    }
  }

  .actions {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 20px;
    width: 100%;
    padding: 16px;
    box-sizing: border-box;

    .back {
      z-index: 1;
      cursor: pointer;
    }

    .cross {
      z-index: 1;
      cursor: pointer;
    }
  }

  .itemContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .icon {
      border-radius: 40px;
      background: $gradient90deg;
      margin-right: 40px;
      width: 80px;
      height: 80px;
      transform: rotate(45deg);
    }

    .bidContent {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: calc(100% - 120px);
      .name {
        @include gradientText180deg;
        font-weight: 800;
        font-size: 32px;
        line-height: 40px;
        margin-right: auto;
      }

      .bidInfo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 250px;
        .by {
          font-size: 24px;
          line-height: 30px;
          color: gray;
        }

        .text {
          font-weight: 900;
          font-size: 18px;
          line-height: 24px;
          color: #000;
          margin-left: auto;
        }
      }
    }
  }
  @media screen and (max-width: 800px) {
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 60px;
    .itemContainer {
      justify-content: flex-start;
      .icon {
        margin-right: 20px !important;
      }
      .bidContent {
        flex-direction: column;
        align-items: initial;

        .bidInfo {
          justify-content: space-between;
          width: auto !important;
        }
      }
    }
  }
}
.containerSubmit {
  max-width: 508px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  position: relative;
  padding: 0 16px 15px 16px;
  box-sizing: border-box;
  background-image: url("../../../public/images/starsDark.svg");
  background-size: cover;

  .heading {
    @include gradientText90deg;
    margin: 10px 0 40px 0;
    font-weight: 800;
    font-size: 60px;
    line-height: 76px;
  }
  @media screen and (max-width: 500px) {
      padding-top: 40px !important;
      .heading {
        font-size: 32px;
        line-height: 40px;
        text-align: center;
    }
  }
}

.containerImg {
  display: flex;
  justify-content: center;
  background: #000;
  border-radius: 40px;
  margin-bottom: 20px;
  width: 100%;
  .img {
    width: 100%;
  }
}

.submit {
  padding: 16px 64px;
  font-weight: 900;
  font-size: 18px;
  line-height: 24px;
  border-radius: 50px;
  background: $gradient90deg;
  color: #fff;
  z-index: 1;
  position: relative;
  border: none;
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    left: 0%;
    right: 0%;
    top: 72.38%;
    bottom: 0%;
    background: linear-gradient(98.01deg, #ff00e5 -1.14%, #7000ff 90.23%);
    filter: blur(19px);
    z-index: -1;
  }

  &:hover {
    background: $gradientPink;
  }
  &:active {
    &::after {
      content: none;
    }
  }
}

.paper {
  border-radius: 60px !important;
  // max-width: calc(100%-32px) !important;
  @media screen and (max-width: 1000px) {
    max-width: calc(100% - 32px) !important;
    width: calc(100% - 32px) !important;
    margin-left: 16px !important;
    margin-right: 16px !important;
  }
  @media screen and (max-width: 450px) {
    border-radius: 0 !important;
    margin: 0 !important;
    width: 100% !important;
    max-width: 100% !important;
    height: 100%;
  }
}
